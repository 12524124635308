export const SectionPic = () => (
    <div className="w-full flex justify-center mt-4 px-6 lg:px-0">
        <div className="mt-4 mb-4">
            <div className="text-lg lg:text-3xl font-tenor font-medium">DE FOTO'S</div>
            <div className="text-gray-600 text-normal font-extralight">
                Hieronder vindt u de verschillende albums met foto's van onze dag.
            </div>
            <div className="grid lg:grid-cols-3 grid-cols-1 gap-x-3 gap-y-1">
                <a className="custom-button lg:mt-4 mt-2 block"
                   target="_blank"
                   rel="noreferrer"
                   href="https://photos.app.goo.gl/QBbbJ1e9DfZPRnreA">
                    Foto's van de fotograaf.
                </a>
                <a className="custom-button lg:mt-4 mt-2 block"
                   target="_blank"
                   rel="noreferrer"
                   href="https://photos.app.goo.gl/yKJrTZTNkAHk9MLR6">
                    Foto's van jullie.
                </a>
                <a className="custom-button lg:mt-4 mt-2 block"
                   target="_blank"
                   rel="noreferrer"
                   href="https://photos.app.goo.gl/zsFVUVaCebAoRyQq7">
                    Foto's van de photobooth.
                </a>
            </div>
        </div>
    </div>
)